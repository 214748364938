/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import CountUp from 'react-countup';
import PropTypes from "prop-types";
import WordCloudWelcome from "./WordCloud/WordCloudWelcome";
import axios from "axios";
import { loadAuthToken } from "../../../utils/auth";
import { PulseLoader } from "react-spinners";

import translations from '../translations'

const useStyles = makeStyles({
  outerDiv: {
    margin: "auto",
    textAlign: "center",
  },
  imageDiv: {
    // paddingTop: '1rem',
    paddingBottom: "2rem",
  },
  text: {
    color: "#000",
    fontSize: "2rem",
    margin: "15px",
  },
  largeText: {
    color: "#2A7050",
    fontSize: "xxx-large",
    margin: "15px",
  },
  messageIcon: {
    color: "#6CCA98",
    height: "100px",
    width: "100px",
  },
  completions: {
    fontSize: "1.4rem",
    marginTop: "50px",
  },
  image: {
    width: "300px",
    marginTop: "-35px",
  },
});

const WordCloud = ({
  moduleTitle,
  sessionId,
  moduleId,
  channel,
  isLoading,
  isDemo,
  lang

}) => {
  const [wordCloudQuestions, setWordCloudQuestions] = useState([]);
  const [wordCloudIcon, setWordCloudIcon] = useState("");
  const [totalCompleted, setTotalCompleted] = useState(0);

  const getWordCloudData = () => {
    if (moduleId && sessionId) {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_EVENT_SERVICE}/get-host-word-cloud-data?moduleId=${moduleId}&sessionId=${sessionId}`,
        headers: {
          Authorization: `bearer ${loadAuthToken()}`,
        },
      })
        .then((_res) => {
          console.log("ids _res", _res);
          if (_res.data.questions) {
            setWordCloudQuestions(_res.data.questions);
            setWordCloudIcon(_res.data.icon);
            setTotalCompleted(_res.data.totalCompleted);
          }
        })
        .catch((e) => console.log(e));
    }
  };

  useEffect(() => {
    // console.log("ids", moduleId, sessionId);
    getWordCloudData();
  }, [moduleId, sessionId]);

  const classes = useStyles();

  return isLoading ? (
    <PulseLoader
      sizeUnit="px"
      size={15}
      color="#60A681"
      loading
      speedMultiplier={".5"}
    />
  ) : (
    <div className={classes.outerDiv}>
      <div className={classes.imageDiv}>
        {wordCloudIcon ? (
          <img
            src={wordCloudIcon}
            className={classes.image}
            alt="Stereotype Mask"
          />
        ) : (
          <img
            src="https://s3.amazonaws.com/join.host/stereotypeMasks-sample.png"
            className={classes.image}
            alt="Stereotype Mask"
          />
        )}
      </div>
      <p className={classes.largeText}>{moduleTitle}</p>
      <br />
      <p className={classes.text}>{translations[lang].please_share}</p>
      <br />
      <WordCloudWelcome
        wordCloudQuestions={wordCloudQuestions}
        wordCloudIcon={wordCloudIcon}
        totalCompleted={totalCompleted}
        sessionId={sessionId}
        moduleId={moduleId}
        channel={channel}
        getWordCloudData={getWordCloudData}
        setTotalCompleted={setTotalCompleted}
        isDemo={isDemo}
        lang={lang}
      />
    </div>
  );
};

WordCloud.propTypes = {
  moduleTitle: PropTypes.string.isRequired,
  sessionId: PropTypes.number.isRequired,
  moduleId: PropTypes.number.isRequired,
  channel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  // wordCloudIcon: PropTypes.string,
};

export default WordCloud;
