import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import { PulseLoader } from "react-spinners";

import translations from '../../translations'

const useStyles = makeStyles({
  buttons: {
    bottom: "40px",
    left: "28px",
    position: "absolute",
    color: "#b7b7b7",
    zIndex: "1",
  },
});


const VideoSlide = ({ videoUrl, isLoading, lang }) => {
  const classes = useStyles();

const playFunction = () => {

  
  var video = document.getElementById("myVideo");

  // Get the button
  var btn = document.getElementById("playBtn");
  if (video.paused) {
    video.play();
    video.style.display = "block";

    btn.innerHTML = translations[lang].pause
    ;
  } else {
    video.pause();
    btn.innerHTML = translations[lang].start
    ;
  }
};

const backFunction = () => {
  var video = document.getElementById("myVideo");

  video.currentTime = video.currentTime - 10;
};

const forwardFunction = () => {
  var video = document.getElementById("myVideo");

  video.currentTime = video.currentTime + 10;
};

  return isLoading ? (
    <PulseLoader
      sizeUnit="px"
      size={15}
      color="#60A681"
      loading
      speedMultiplier={".5"}
    />
  ) : (
    <>
      <video
        id="myVideo"
        style={{
          position: "fixed",
          bottom: "10px",
          maxWidth: "90%",
          maxHeight: "90%",
        }}
      >
        <source src={videoUrl} type="video/mp4" />
      </video>
      <div className={classes.buttons}>
        <Button
          style={{ color: "#b7b7b7" }}
          onClick={() => backFunction()}
          id="skipBackdBtn"
        >
          {<SkipPreviousIcon />}{" "}
        </Button>
        <Button
          onClick={() => playFunction()}
          style={{ color: "#b7b7b7" }}
          id="playBtn"
        >
        {translations[lang].start}

        </Button>
        <Button
          style={{ color: "#b7b7b7" }}
          onClick={() => forwardFunction()}
          id="skipForwardBtn"
        >
          {<SkipNextIcon />}
        </Button>
      </div>
    </>
  );
};

VideoSlide.propTypes = {
  videoUrl: PropTypes.string,
  title: PropTypes.string,
};

export default VideoSlide;
