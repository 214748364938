import axios from "axios";
import React, { useEffect, useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { loadAuthToken } from "../../../utils/auth";
import { AuthContext } from "../../../Auth";
import SurveyImg from "../../../assests/img/checkbox.png";
import { PulseLoader } from "react-spinners";
import translations from "../translations"

const useStyles = makeStyles({
  outerDiv: {
    margin: "auto",
    textAlign: "center",
  },
  imageDiv: {
    marginTop: "-120px",
  },
  contentDiv: {
    // paddingTop: "2.5rem",
  },
  title: {
    fontSize: "xxx-large",
    color: "#2A7050",
  },
  text: {
    fontSize: "1.4rem",
    color: "#000",
  },
  largeText: {
    fontSize: "1.75rem",
    color: "#000",
  },
  messageIcon: {
    color: "#6CCA98",
    height: "100px",
    width: "100px",
  },
  image: {
    width: "300px",
    marginBottom: "5px",
  },
  number: {
    color: "#60A681",
    fontSize: "35px",
  },
});

const Survey = ({
  moduleTitle,
  sessionId,
  moduleId,
  channel,
  modules,
  isLoading,
  lang,
}) => {
  const [usersAnswered, setUsersAnswered] = useState(0);
  const context = useContext(AuthContext);
  const index = localStorage.getItem("slideIndex");

  const getFinishedUsers = () => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_EVENT_SERVICE}/survey-answered?sessionId=${sessionId}&moduleId=${moduleId}`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
    })
      .then((res) => {
        if (
          res.data === "your token is not valid" ||
          res.data === "Your token is expired"
        ) {
          context.sendToLogin();
        }
        setUsersAnswered(res.data.usersAnswered);
      })
      .catch((err) => {
        if (
          err.error === "your token is not valid" ||
          err.error === "Your token is expired"
        ) {
          context.sendToLogin();
        }
      });
  };

  useEffect(() => {
    getFinishedUsers();
    if (channel) {
      channel.bind(`survey_completion_${sessionId}_${moduleId}`, (message) => {
        setUsersAnswered(message);
      });
      return () => {
        channel.unbind(`survey_completion_${sessionId}_${moduleId}`);
      };
    }
  }, [moduleId, channel, sessionId]);


  const classes = useStyles();
  return isLoading ? (
    <PulseLoader
      sizeUnit="px"
      size={15}
      color="#60A681"
      loading
      speedMultiplier={".5"}
    />
  ) : (
    <div className={classes.outerDiv}>
      <div className={classes.imageDiv}>
        <img src={SurveyImg} className={classes.image} alt="survey" />
      </div>
      <div className={classes.contentDiv}>
        <p className={classes.title}>
          <strong>{moduleTitle}</strong>
        </p>
        <br />
        <p className={classes.largeText}>
          {translations[lang].thank_you}
        </p>
        <br />
        <br />
        {/* <p className={classes.text}>
          Please take a moment to answer these last few questions
        </p> */}
        <br />
        <p className={classes.number}>
          <strong> {usersAnswered ? String(usersAnswered) : "0"}</strong>
        </p>
        <p className={classes.text}>{translations[lang].completions}</p>
        <div
          width="100%"
          align="flex-end"
          style={{ right: "5vw", bottom: "7vh", position: "absolute" }}
        />
      </div>
    </div>
  );
};

Survey.propTypes = {
  moduleTitle: PropTypes.string.isRequired,
  sessionId: PropTypes.number.isRequired,
  moduleId: PropTypes.number.isRequired,
  channel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  modules: PropTypes.array,
};

export default Survey;
