import React from "react";
import clsx from "clsx";
import HostView from "../views/Host/Host";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components

// import routes from '../routes';

import styles from "../assests/jss/material-dashboard-pro-react/layouts/hostStyle";

const useStyles = makeStyles(styles);

export const HostLayoutContext = React.createContext({});

export default function Pages(props) {
  // const { ...rest } = props;
  // ref for the wrapper div
  const wrapper = React.createRef();
  // styles
  const classes = useStyles();
  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });
  // const getRoutes = (allRoutes) => allRoutes.map((prop, key) => {
  //   if (prop.collapse) {
  //     return getRoutes(prop.views);
  //   }
  //   if (prop.layout === '/host') {
  //     return (
  //       <Route
  //         path={prop.path}
  //         component={prop.component}
  //         key={key}
  //       />
  //     );
  //   }
  //   return null;
  // });

  return (
    <div>
      <div className={clsx(classes.wrapper, "outerDiv")} ref={wrapper}>
        <div className={classes.innerPage}>
          <HostLayoutContext.Provider value={{}}>
            <HostView />
          </HostLayoutContext.Provider>
        </div>
      </div>
    </div>
  );
}
