import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import QRCode from "react-qr-code";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";

import translations from "../../translations";

// TODO create seperate style sheet to import to all slides!!!
const useStyles = makeStyles({
  outerDiv: {
    margin: "auto",
    textAlign: "center",
  },
  imageDiv: {
    // position: "relative",
    // paddingTop: "56.25%",
  },
  contentDiv: {
    paddingTop: "1.5rem",
  },
  text: {
    fontSize: "1.4rem",
    margin: "25px 0px 7px",
  },
  largeText: {
    fontSize: "1.6rem",
  },
  emphasize: {
    fontSize: "xx-large",
    color: "#2A7050",
    margin: "0px",
  },
  title: {
    color: "#2A7050",
    fontSize: "xx-large",
    margin: "0px 0px 25px 0px",
    width: "max-content",
  },
  gridContainer: {
    display: "flex",
    direction: "row",
    justifyContent: "center",
    flexWrap: "nowrap",
  },
  gridItem1: {
    paddingRight: "14vw",
    // flexBasis: "100%",
  },
  gridItem2: {
    // flexBasis: "100%",
  },
  topOfSectionText: {
    margin: "0px 0px 7px",
  },
  boxShadow: {
    border: "1px solid #b7b7b7",
    boxShadow: "20px 18px 12px #b7b7b7",
  },
  buttons: {
    bottom: "40px",
    left: "28px",
    position: "absolute",
    fontColor: "#b7b7b7",
    zIndex: "1",
    "button &": {
      color: "#00FF00",
    },
  },
  copyButton: {
    margin: "25px 0px 7px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "3px",
    height: "47px",
    backgroundColor: "#60A681",
    cursor: "pointer",
    padding: "10px 25px",
  },
});

const WelcomeSlide = ({ title, videoUrl, pin, lang }) => {
  console.log(lang);
  const classes = useStyles();
  const [btnText, setBtnText] = useState(translations[lang].copyInfo);

  const playFunction = () => {
    var video = document.getElementById("myVideo");

    // Get the button
    var btn = document.getElementById("playBtn");
    if (video.paused) {
      video.play();
      // video.style.display = "block";

      btn.innerHTML = translations[lang].pause;
    } else {
      video.pause();
      btn.innerHTML = translations[lang].start;
    }
  };

  const backFunction = () => {
    var video = document.getElementById("myVideo");

    video.currentTime = video.currentTime - 10;
  };

  const forwardFunction = () => {
    var video = document.getElementById("myVideo");

    video.currentTime = video.currentTime + 10;
  };

  const copyURL = () => {
    navigator.clipboard.writeText(`www.join.host/event/${pin}`);
    setBtnText(translations[lang].copied);
    setTimeout(() => {
      setBtnText(translations[lang].copyInfo);
    }, 1000);
  };

  return (
    <Grid
      container
      alignItems="center"
      style={{
        // height: '100%',
        width: "100%",
        alignItems: "center",
        justifyContent: "space-around",
        alignContent: "center",
        margin: "4%",
      }}
    >
      <Grid
        item
        lg={4}
        style={{
          zIndex: 2,
          height: "76vh",
        }}
      >
        <div>
          {/* <h3 className={classes.topOfSectionText}>Welcome to the</h3>
          <p className={classes.title}>{title}</p> */}
          <h3 className={classes.text}>{translations[lang].translatorIs}</h3>
          <p className={classes.emphasize}>{translations[lang].anonymous}</p>
          <h3 className={classes.text}>{translations[lang].visit}</h3>
          <p className={classes.emphasize}>www.join.host</p>
          <h3 className={classes.text}>{translations[lang].enterPin}</h3>
          <p className={classes.emphasize}>{pin}</p>

          <QRCode
            value={`www.join.host/event/${pin}`}
            style={{ marginTop: "50px" }}
            size={150}
          />
          <div>
            <Button className={classes.copyButton} onClick={() => copyURL()}>
              {btnText}
            </Button>
          </div>
          <div className={classes.buttons}>
            <Button
              // className={classes.back}
              style={{ color: "#b7b7b7" }}
              onClick={() => backFunction()}
              id="skipBackdBtn"
            >
              {<SkipPreviousIcon />}{" "}
            </Button>
            <Button
              // className={classes.button}
              onClick={() => playFunction()}
              style={{ color: "#b7b7b7" }}
              id="playBtn"
            >
              {translations[lang].start}
            </Button>
            <Button
              // className={classes.forward}
              style={{ color: "#b7b7b7" }}
              onClick={() => forwardFunction()}
              id="skipForwardBtn"
            >
              {<SkipNextIcon />}
            </Button>
          </div>
        </div>
      </Grid>
      <Grid item lg={8}>
        <video
          id="myVideo"
          style={{
            position: "fixed",
            right: "20px",
            bottom: "10px",
            maxWidth: "75%",
            maxHeight: "75%",
            display: "block",
          }}
        >
          <source src={videoUrl} type="video/mp4" />
        </video>
      </Grid>
    </Grid>
  );
};

WelcomeSlide.propTypes = {
  text: PropTypes.string,
  videoUrl: PropTypes.string,
  pin: PropTypes.number,
};

export default WelcomeSlide;
