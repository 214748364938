/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
// import InfoModule from './InfoModule';
import WalkOfPrivilege from "./WalkOfPrivilege";
import Default from "./Default";
import AutoHostSlide from "./AutoHostSlide";
// import SignUp from './SignUp';
import Survey from "./Survey";
// import SentimentSurvey from './SentimentSurvey';
// import KeyWordSurvey from './keyWordSurvey/KeyWordSurvey';
import WordCloud from "./WordCloud";
import Comfort from "./ComfortMeter";
// import Spectrum from './Spectrum';
import Poll from "./Poll/Poll";
import WordPopcorn from "./WordPopcorn";

const index = (props) => {
  const {
    currentSlide,
    moduleType,
    moduleTitle,
    moduleId,
    currentModuleId,
    pin,
    sessionId,
    walkOfPrivilegeQuestions,
    surveyQuestions,
    channel,
    isDemo,
    modules,
    lang
  } = props;

  switch (moduleType) {
    case "autoHostSlide": {
      return (
        <AutoHostSlide
          moduleId={moduleId}
          moduleTitle={moduleTitle}
          moduleType={moduleType}
          pin={pin}
          currentModuleId={currentModuleId}
          sessionId={sessionId}
          modules={modules}
          isDemo={isDemo}
          lang={lang}
        />
      );
    }
    case "walkOfPrivilege": {
      return (
        <WalkOfPrivilege
          channel={channel}
          isDemo={isDemo}
          moduleId={moduleId}
          moduleTitle={moduleTitle}
          sessionId={sessionId}
          walkOfPrivilegeQuestions={walkOfPrivilegeQuestions}
          currentModuleId={currentModuleId}
          lang={lang}
        />
      );
    }
    case "infoModule": {
      return (
        // <InfoModule
        //   moduleTitle={moduleTitle}
        // />
        <div />
      );
    }
    case "survey": {
      return (
        <Survey
          moduleId={currentModuleId}
          channel={channel}
          moduleTitle={moduleTitle}
          sessionId={sessionId}
          surveyQuestions={surveyQuestions}
          modules={modules}
          lang={lang}
        />
      );
    }
    case "sentimentSurvey": {
      return (
        // <SentimentSurvey
        //   moduleId={moduleId}
        //   channel={channel}
        //   moduleTitle={moduleTitle}
        //   isDemo={isDemo}
        //   sessionId={sessionId}
        //   surveyQuestions={surveyQuestions}
        // />
        <div />
      );
    }
    case "keyWordSurvey": {
      return (
        // <KeyWordSurvey
        //   moduleId={moduleId}
        //   channel={channel}
        //   moduleTitle={moduleTitle}
        //   isDemo={isDemo}
        //   sessionId={sessionId}
        //   surveyQuestions={surveyQuestions}
        // />
        <div />
      );
    }
    case "wordCloud": {
      return (
        <WordCloud
          moduleId={currentModuleId}
          channel={channel}
          moduleTitle={moduleTitle}
          sessionId={sessionId}
          isDemo={isDemo}
          lang={lang}
        />
      );
    }
    case "comfort": {
      return (
        <Comfort
          moduleId={currentModuleId}
          channel={channel}
          moduleTitle={moduleTitle}
          // isDemo={isDemo}
          sessionId={sessionId}
          lang={lang}
        />
      );
    }
    case "spectrum": {
      return (
        // <Spectrum
        //   moduleId={moduleId}
        //   channel={channel}
        //   moduleTitle={moduleTitle}
        //   isDemo={isDemo}
        //   sessionId={sessionId}
        // />
        <div />
      );
    }
    case "poll": {
      return (
        <Poll
          moduleId={currentModuleId}
          channel={channel}
          moduleTitle={moduleTitle}
          sessionId={sessionId}
          lang={lang}
        />
      );
    }
    case "wordPopcorn": {
      return (
        <WordPopcorn
          moduleId={moduleId}
          currentSlide={currentSlide}
          channel={channel}
          moduleTitle={moduleTitle}
          sessionId={sessionId}
          currentModuleId={currentModuleId}
          isDemo={isDemo}
          lang={lang}
        />
      );
    }
    default: {
      return <Default />;
    }
  }
};

index.propTypes = {
  moduleType: PropTypes.string,
  moduleTitle: PropTypes.string,
  moduleId: PropTypes.number,
  pin: PropTypes.number.isRequired,
  sessionId: PropTypes.number.isRequired,
  walkOfPrivilegeQuestions: PropTypes.array,
  surveyQuestions: PropTypes.array,
  channel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  isDemo: PropTypes.bool,
  currentModuleId: PropTypes.number,
  // wopModuleId: PropTypes.number.isRequired,
  // wordCloudModuleId: PropTypes.number.isRequired,
  // pollModuleId: PropTypes.number.isRequired,
  // czModuleId: PropTypes.number,
  modules: PropTypes.array.isRequired,
};

export default index;
