import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import translations from './translations'


const useStyles = makeStyles({
  pin: {
    bottom: "35px",
    position: "absolute",
    display: "flex",
    justifyContent: "space-between",
    minWidth: "215px",
  },
  innerDiv: {
    background: "#999999ad",
    borderRadius: "15px",
    color: "white",
    padding: "10px 20px 12px",
    margin: "0 10px",
  },
  text: {
    fontSize: "1.75rem",
    letterSpacing: "0.15em !important",
  },
});

function Stats(props) {
  const { pin, lang } = props;

  const classes = useStyles();

  return (
    <div className={classes.pin}>
      <div className={classes.innerDiv}>
        <p className={classes.text}>{`www.join.host   |   ${translations[lang].pin}: ${pin}`}</p>
      </div>
    </div>
  );
}

Stats.propTypes = {
  pin: PropTypes.number.isRequired,
};

export default Stats;
