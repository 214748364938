import React, {useState} from "react";
import PropTypes from "prop-types";
import { Grid, makeStyles } from "@material-ui/core";
import QRCode from "react-qr-code";

import Button from "@material-ui/core/Button";


import logo from "../../../../assests/img/translator-logo.png"

// TODO create seperate style sheet to import to all slides!!!
const useStyles = makeStyles({
  outerDiv: {
    margin: "auto",
    textAlign: "center",
  },
  imageDiv: {
    // position: "relative",
    // paddingTop: "56.25%",
  },
  contentDiv: {
    paddingTop: "1.5rem",
  },
  text: {
    fontSize: "1.4rem",
    margin: "25px 0px 7px",
  },
  largeText: {
    fontSize: "1.6rem",
  },
  emphasize: {
    fontSize: "xx-large",
    color: "#2A7050",
    margin: "0px",
  },
  title: {
    color: "#2A7050",
    fontSize: "xx-large",
    margin: "0px 0px 25px 0px",
    width: "max-content",
  },
  gridContainer: {
    display: "flex",
    direction: "row",
    justifyContent: "center",
    flexWrap: "nowrap",
  },
  gridItem1: {
    paddingRight: "14vw",
    // flexBasis: "100%",
  },
  gridItem2: {
    // flexBasis: "100%",
  },
  topOfSectionText: {
    margin: "0px 0px 7px",
  },
  boxShadow: {
    border: "1px solid #b7b7b7",
    boxShadow: "20px 18px 12px #b7b7b7",
  },
  buttons: {
    bottom: "40px",
    left: "28px",
    position: "absolute",
    fontColor: "#b7b7b7",
    zIndex: "1",
    "button &": {
      color: "#00FF00",
    },
  },
  infoGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    marginTop: '5%',
    height: '65vh',

  },
  infoImg:{
    backgroundImage: `url(${logo})`,
    overflow: 'hidden',
    backgroundRepeat:' no-repeat',
    backgroundPosition: 'center',
    opacity: '0.15',
    position: 'absolute',
    height: '65vh',
    width: '100%',
    marginLeft: '-10vw',
  },
  listParent:{
    height: '45vh',
    position: 'absolute',
    marginLeft: '-10vw',
  },
  list: {
    marginBottom: '20px',
    fontSize: '25px',
  },
  copyButton: {
    margin: '25px 0px 7px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '3px',
    height: '47px',
    backgroundColor: '#60A681',
    cursor: 'pointer',
    padding: '10px 25px',
  }
});

const WelcomeSlide = ({ title, videoUrl, pin }) => {
  const classes = useStyles();

  const [btnText, setBtnText] = useState('Copy Info');

  const copyURL =() => {
    navigator.clipboard.writeText(`www.join.host/event/${pin}`)
    setBtnText('Copied!');
    setTimeout(() => { 
      setBtnText('Copy Info'); 
  }, 1000)
}
  
  return (
    <Grid
      container
      alignItems="center"
      style={{
        // height: '100%',
        width: "100%",
        alignItems: "center",
        justifyContent: "space-around",
        alignContent: "center",
        margin: "4%",
      }}
    >
      <Grid
        item
        lg={4}
        style={{
          zIndex: 2,
          height: "76vh",
        }}
      >
        <div>
          {/* <h3 className={classes.topOfSectionText}>Welcome to the</h3>
          <p className={classes.title}>{title}</p> */}
          <h3 className={classes.text}>Translator is</h3>
          <p className={classes.emphasize}>100% anonymous</p>
          <h3
            className={classes.text}
            // style={{ fontSize: "1.4rem" }}
          >
            On your phone please visit
          </h3>
          <p className={classes.emphasize}>www.join.host</p>
          <h3 className={classes.text}>Enter this pin</h3>
          <p className={classes.emphasize}>{pin}</p>

          <QRCode
            value={`www.join.host/event/${pin}`}
            style={{ marginTop: "50px" }}
            size={150}
          />
        </div>
        <Button
            className={classes.copyButton}

            onClick={() => 
              copyURL()
            }>
            {btnText}
          </Button>
      </Grid>
      <Grid item lg={8} className={classes.infoGrid}>
      <div className={classes.infoImg}></div>
      <div className={classes.listParent} >
      <h1 style={{fontSize: '3.5em'}}>
        To use anonymous messaging:
      </h1>
      <ul style={{ listStyleType: 'circle'}}>
        <li className={classes.list}>Scan the QR Code or go www.join.host and enter the pin</li>
        <li className={classes.list}>Use the yellow button to send a message</li>
        <li className={classes.list}>Messages are one-directional and only sent to your meeting host</li>
        <li className={classes.list}>Not all messages may be addressed in the meeting</li>
      </ul>

      </div>
     
      </Grid>
    </Grid>
  );
};

WelcomeSlide.propTypes = {
  text: PropTypes.string,
  videoUrl: PropTypes.string,
  pin: PropTypes.number,
};

export default WelcomeSlide;
