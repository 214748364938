import React from "react";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import App from "./Layouts/Host";
import Auth from "./Auth";

const theme = createTheme({
  typography: {
    fontFamily: [
      '"Montserrat"',
      "Roboto",
      '"Helvetica"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

export default function AuthRouter() {
  return (
    <Auth>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route path="/">
              <App />
            </Route>
            <Route render={() => <Redirect to="/" />} />
          </Switch>
        </Router>
      </ThemeProvider>
    </Auth>
  );
}
